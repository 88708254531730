@import 'status-indicator';

* {
    box-sizing: inherit;
}

:root {
    --status-indicator-size: 15px;
}

.MuiListItemIcon-root {
    min-width: 65px !important;
}

body {
    color: #005170;
    margin: 0;
    font-size: 0.9375rem;
    font-family: Futura, Roboto, Arial, sans-serif !important;
    font-weight: 400;
    line-height: 1.43;
    background-color: #d8dfe7;
}

.graph-container {
    display: flex;
    flex-direction: row;
}

.graph-size {
    display: flex;
    min-height: 27.75rem;
    /* height: 33rem; */
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    height: 100%;
    width: 95%;
}

.MuiAccordionSummary-content {
    text-align: center;
    justify-content: flex-end;
}

.MuiAccordionSummary-root {
    padding: 0 !important;
}

.MuiAccordionDetails-root {
    justify-content: center;
}

@media only screen and (max-width: 900px) {
    .graph-container {
        flex-direction: row;
    }

    /* .mobile-flex {
        display:flex;
    } */

    .graph-size {
        height: 300px;
        width: 90%;
    }
}

.white-text-field {
    color: #fff
}