/* errorTable.css */
.scrollable-cell {
  max-height: 120px;
  overflow-y: auto;
  padding: 8px;
  padding-bottom: 20px;
  padding-left: 10px;
  border: 1px solid #dddddda0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
/* Scrollbar styles for WebKit browsers (Chrome, Safari, Edge) */
.scrollable-cell::-webkit-scrollbar {
  width: 6px;
}
.scrollable-cell::-webkit-scrollbar-track {
  background: transparent;
}
.scrollable-cell::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.scrollable-cell::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
/* Scrollbar styles for Firefox */
.scrollable-cell {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.bulk-select-btn {
  width: 200px;
  background-color: transparent;
  color: #005170;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
}
